<template>
    <div class="archive-page scrollpage">
        <div class="container container-xl">
            <h3>{{ entry.title }}</h3>
            <div :class="['archive', 'archive__' + format.id]" v-for="(format, key) in $store.getters.getFormats" v-bind:key="format">
                <div :style="{ '--main-color': format.color }" v-if="filterByFormat(format.id).length > 0">
                    <div class="archive__number">{{key+1}}</div>
                    <Agenda :agenda="filterByFormat(format.id)" />
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import Agenda from '@/components/AgendaComponent.vue';

export default {
    name: 'AgendaPage',
    props: ['entry'],
    components: {
        Agenda
    },
    data(){
        return {

        }
    },
    methods: {
        filterByFormat(id){
            var agenda = [];
            for (var item of this.entry.data){
                for (var format of item.formats){
                    if(format.id == id){
                        agenda.push(item);
                    }
                }
            }
            return agenda;
        },
        getMainColor(id){
            var color = ""
            for (var item of this.entry.data){
                for (var format of item.formats){
                    if(format.id == id){
                        color = format.color;
                    }
                }
            }
            return {
                '--main-color': color
            }
        }

    },
    mounted(){

    },
}
</script>
