<template>
    <div class="about-page scrollpage">
        <div class="container">
            <h3>{{ entry.title }}</h3>
            <div class="text" v-html="entry['contentText']"></div>
            <h4>{{ $t("sponsor") }}</h4>
            <div class="text" v-html="entry.sponsors.lead"></div>
            <div class="logos">
                <template v-for="(logo) in entry.sponsors.logos" v-bind:key="logo">
                    <a v-if="logo.link" :href="logo.link" target="_blank"><img :src="logo.image" alt=""></a>
                    <span v-else><img :src="logo.image" alt=""></span>
                </template>

            </div>
            <h4>{{ $t("links") }}</h4>
            <div class="links">
                <ul>
                    <li v-for="(link) in entry['links']" v-bind:key="link">
                        <a target="_blank" :href="link.urlLink">{{ link.nameLink }}</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>


<script>

export default {
    name: 'AboutPage',
    props: ['entry'],
    components: {

    },
    data(){
        return {

        }
    },
    mounted(){

    },
}
</script>
