<template>
    <div class="agenda-page scrollpage">

        <div class="container container-xl">
            <h3>{{ entry.title }}</h3>
            <Agenda :agenda="entry.data" />
        </div>

    </div>
</template>


<script>
import Agenda from '@/components/AgendaComponent.vue';

export default {
    name: 'AgendaPage',
    props: ['entry'],
    components: {
        Agenda
    },
    data(){
        return {

        }
    },
    mounted(){

    },
}
</script>
