<template>
    <div class="team-page scrollpage">
        <div class="container container-lg">
            <h3>{{ entry.title }}</h3>
            <table>
                <tr @click="$refs.circlePopup.setCPerson(team)" v-for="(team) in entry['team']" v-bind:key="team">
                    <td style="width: 50%;"><div>{{ team.title }}</div></td>
                    <td style="width: 50%;"><div>{{ team.category.join(', ') }}</div></td>
                </tr>
            </table>
            <CirclePopup ref="circlePopup" />
        </div>
        <div class="container">
            <h4>{{ $t("partner") }}</h4>
            <div class="partner" v-for="(partner) in entry['partners']" v-bind:key="partner">
                <div class="partner-text text" v-html="partner.contentText"></div>
                <a :href="partner.websiteLink" target="_blank">{{ partner.websiteName }}</a>
            </div>
        </div>
        <div class="container container-lg">
            <h4>{{ $t("Involved") }}</h4>
            <table>
                <tr @click="$refs.circlePopup.setCPerson(participant)" v-for="(participant) in entry['participants']" v-bind:key="participant">
                    <td><div>{{ participant.title }}</div></td>
                    <td>
                        <div>
                            <div v-html="getParticipantsCategory(participant.category)"></div>
                            <div class="formats" v-if="participant.formats">
                                <a v-for="format in participant.formats" v-bind:key="format" :style="`background-color: ${format.color}`" :href="$url(`formats/${format.slug}`)">
                                    {{ format.number }}
                                </a>
                            </div>
                        </div>
                    </td>
                    <td>
                        <div>
                            <div class="formats" v-if="participant.formats">
                                <a v-for="format in participant.formats" v-bind:key="format" :style="`background-color: ${format.color}`" :href="$url(`formats/${format.slug}`)">
                                    {{ format.number }}
                                </a>
                            </div>
                        </div>
                    </td>
                </tr>
            </table>
        </div>

    </div>
</template>


<script>
import CirclePopup from '@/components/CirclePopupComponent.vue';


export default {
    name: 'TeamPage',
    props: ['entry'],
    components: {
        CirclePopup
    },
    data(){
        return {
            person: null,
        }
    },
    mounted(){

    },
    methods: {
        getParticipantsCategory(category){
            return category.join(', ').replace('Work it Out', 'Work <del>it</del> Out');
        }
    }
}
</script>
