<template>
  <div class="page">

      <SubMenu>
          <template v-slot:about>
            <AboutPage :entry="$store.getters.getPageById(151)"/>
          </template>
          <template v-slot:team>
            <TeamPage :entry="$store.getters.getPageById(154)"/>
          </template>
          <template v-slot:agenda>
            <AgendaPage :entry="$store.getters.getPageById(166)"/>
          </template>
          <template v-slot:archive>
            <ArchivePage :entry="$store.getters.getPageById(169)"/>
          </template>
          <template v-slot:contact>
            <ContactPage :entry="$store.getters.getPageById(172)"/>
          </template>
      </SubMenu>
  </div>
</template>


<script>
import SubMenu from '@/components/SubMenuComponent.vue';
//components pages
import AboutPage from '@/components/page/AboutPageComponent.vue';
import TeamPage from '@/components/page/TeamPageComponent.vue';
import AgendaPage from '@/components/page/AgendaPageComponent.vue';
import ArchivePage from '@/components/page/ArchivePageComponent.vue';
import ContactPage from '@/components/page/ContactPageComponent.vue';


export default {
  name: 'Page',
  components: {
      SubMenu,
      AboutPage,
      TeamPage,
      AgendaPage,
      ArchivePage,
      ContactPage
  },
  created() {

  },
  methods: {
      setPage(slug){
          this.$store.commit('setCurrentPage', slug);
      },
      isMobile(){
          return (window.$(window).width() <= 768) ? true : false;
      }
  },
  mounted() {
      window.$("body").removeClass().addClass('pages');
      this.$store.commit('hideSubmenu');
      this.unwatch = this.$store.watch(
          (state, getters) => getters.getPage,
          (newValue) => {
              if(newValue){
                  this.$setHeaderData({title: newValue.title });
              }
          }
      );
      this.setPage(this.$route.params.slug);

      var _this = this;
      var linkDone = true;
      /*document.addEventListener('scroll', function (event) {

          if (window.$(event.target).hasClass('scrollpage')) {
            try{
              var element = event.target;
              let currentIndex = window.$("ul.subMenu__menu__items > li.subMenu__menu__item.subMenu__menu__item-active").index();

              if (element.scrollHeight - element.scrollTop === element.clientHeight) {
                  if(currentIndex+1 <  window.$("ul.subMenu__menu__items > li.subMenu__menu__item").length){
                    setTimeout(function(){
                      event.target.scrollTo(0, 100);
                    }, 500);
                  }
                  let link = window.$("ul.subMenu__menu__items > li.subMenu__menu__item").eq(currentIndex+1).find("a").eq(0);
                  if(linkDone){
                    linkDone = false;
                    _this.$router.push(link.attr("href"));
                    setTimeout(function(){
                      linkDone = true;
                    }, 500);
                  }
              }

              if(element.scrollTop <= 0){
                let link = window.$("ul.subMenu__menu__items > li.subMenu__menu__item").eq(currentIndex-1).find("a").eq(0);
                if(link.attr("href") != "/") _this.$router.push(link.attr("href"));
              }
            }catch(error){/!*error*!/}

          }

      }, true);*/

  },
  beforeUnmount() {
      this.unwatch();
  },
  watch: {
      $route(to) {
          this.setPage(to.params.slug);
      }
  }
}
</script>
