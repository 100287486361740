<template>
    <div class="contact-page scrollpage">
        <div class="container">
            <h3>{{ entry.title }}</h3>
            <p class="mbottom">{{ $t("Write us") }}</p>
            <p><a class="link" :href="'mailto:' + $store.getters.getGeneral.email">{{ $store.getters.getGeneral.email }}</a></p>
            <p class="mbottom" style="margin-top: 50px;">{{ $t("Social Media") }}</p>
            <p class="mbottom"><a class="link externalLink" target="_blank" :href="$store.getters.getGeneral.instagram">Instagram</a></p>
            <p><a class="link externalLink" target="_blank" :href="$store.getters.getGeneral.facebook">Facebook</a></p>
            <h4>{{ $t("imprint") }}</h4>
            <div class="text" v-html="entry['disclaimer']"></div>
            <h4>{{ $t("privacy") }}</h4>
            <div class="text text-privacy" v-html="textLegal"></div>
            <div @click="showAllContent()" class="showMore"></div>
        </div>
    </div>
</template>


<script>
import clip from "text-clipper"

export default {
    name: 'ContactPage',
    props: ['entry'],
    components: {

    },
    data(){
        return {
            textLegal: this.entry.privacy,
        }
    },
    mounted(){
      this.textLegal = this.clipContent(this.textLegal);
    },
    methods: {
      clipContent(content){
        return clip(this.textLegal, 1700, { html: true, maxLines: 70 });
      },
      showAllContent(){
        if(this.textLegal.length == this.entry.privacy.length){
          this.textLegal = this.clipContent(this.textLegal);
        }else{
          this.textLegal = this.entry.privacy;
        }

      }
    }
}
</script>
